import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col } from "../../UI/Layout/Col.tsx";
import { TextInput } from "../../UI/TextInput.tsx";
import { InputWithLabel } from "../../UI/InputWithLabel.tsx";
import { Row } from "../../UI/Layout/Row.tsx";
import { PrimaryButton } from "../../UI/PrimaryButton.tsx";
import { useWithLoading } from "../../UI/Loading/useWithLoading.ts";
import dayjs, { Dayjs } from "dayjs";
import { useCalculatePlanGivenSlot } from "./useCalculatePlanGivenSlot.ts";
import { isDefined } from "../../isDefined.ts";
import { convertToInt } from "../../DataUtils/convertToInt.ts";
import { SpinnerContainer } from "../../UI/Loading/SpinnerContainer.tsx";

export type ClassRoomPlanFormInputs = {
  startAtWeek: Date;
  endAtWeek: Date;
  lessonsPerWeek: number;
};

const schema = yup
  .object({
    startAtWeek: yup
      .date()
      .typeError("Devi specificare una data valida")
      .required("Inserisci una data di inizio"),
    endAtWeek: yup
      .date()
      .typeError("Devi specificare una data valida")
      .required("Inserisci una data di fine"),
    lessonsPerWeek: yup
      .number()
      .typeError("Devi specificare un numero")
      .integer()
      .required("Inserisci il numero di lezioni a settimana"),
  })
  .required();

type Props = {
  defaultValues?: ClassRoomPlanFormInputs;
  onSave: (data: ClassRoomPlanFormInputs) => Promise<void>;
  disabledMap?: {
    [key in keyof ClassRoomPlanFormInputs]?: boolean;
  };
};

export function ClassRoomPlanForm({
  defaultValues,
  onSave,
  disabledMap,
}: Props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
  } = useForm<ClassRoomPlanFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...enrichDefaultValuesWithDefaultLessonPerWeekIfNotSpecified(
        enrichDefaultValuesWithDefaultDatesIfNotSpecified(
          defaultValues ?? ({} as ClassRoomPlanFormInputs),
        ),
      ),
    },
  });

  function enrichDefaultValuesWithDefaultDatesIfNotSpecified(
    prevDefaultValue: ClassRoomPlanFormInputs,
  ): ClassRoomPlanFormInputs {
    if (prevDefaultValue.startAtWeek || prevDefaultValue.endAtWeek)
      return prevDefaultValue;
    const start = parseDateInUtc(
      dayjs().add(1, "week").startOf("week").toDate(),
    ).toDate();
    const end = dayjs(start).add(24, "week").endOf("week").toDate();
    return {
      ...prevDefaultValue,
      startAtWeek: start,
      endAtWeek: end,
    };
  }

  function enrichDefaultValuesWithDefaultLessonPerWeekIfNotSpecified(
    prevDefaultValue: ClassRoomPlanFormInputs,
  ): ClassRoomPlanFormInputs {
    if (prevDefaultValue.lessonsPerWeek) return prevDefaultValue;
    return {
      ...prevDefaultValue,
      lessonsPerWeek: 2,
    };
  }

  const startDate = watch("startAtWeek");
  const endDate = watch("endAtWeek");
  const lessonsPerWeek = watch("lessonsPerWeek");

  const { withLoading, loading } = useWithLoading();

  const { calculatedGivenSlot, loading: loadingGivenSlotCount } =
    useCalculatePlanGivenSlot({
      startedAtWeekUtc:
        isDefined(startDate) && dayjs(startDate).isValid()
          ? getStartDate(startDate).toISOString()
          : undefined,
      endedAtWeekUtc:
        isDefined(endDate) && dayjs(endDate).isValid()
          ? getEndDate(endDate).toISOString()
          : undefined,
      lessonsPerWeek: isDefined(lessonsPerWeek)
        ? convertToInt(lessonsPerWeek)
        : undefined,
    });

  function mapData(data: ClassRoomPlanFormInputs): ClassRoomPlanFormInputs {
    return {
      ...data,
      startAtWeek: getStartDate(data.startAtWeek).toDate(),
      endAtWeek: getEndDate(data.endAtWeek).toDate(),
    };
  }
  const onSubmit: SubmitHandler<ClassRoomPlanFormInputs> = async (data) => {
    await withLoading(async () => onSave(mapData(data)));
  };

  function parseDateInUtc(date: Date): Dayjs {
    return dayjs.tz(dayjs(date).format("YYYY-MM-DD"), "UTC").utc();
  }
  function getStartDate(sDateVal: Date): Dayjs {
    return parseDateInUtc(sDateVal).startOf("week");
  }

  function getEndDate(eDateVal: Date): Dayjs {
    return parseDateInUtc(eDateVal).endOf("week");
  }

  function printPlanInfo(
    sDate?: Date,
    eDate?: Date,
    calculatedGivenSlot?: number,
  ) {
    if (
      !isDefined(sDate) ||
      !isDefined(eDate) ||
      !dayjs(sDate).isValid() ||
      !dayjs(eDate).isValid()
    )
      return "";
    const startDate = getStartDate(sDate);
    const endDate = getEndDate(eDate);
    if (!startDate.isValid() || !endDate.isValid()) {
      return "";
    }

    const parts: string[] = [
      `The plan you are creating will go from the week starting ${startDate.locale("en").format("ddd DD MMM YYYY")} to the week ending ${endDate.format(
        "ddd DD MMM YYYY",
      )}`,
    ];
    if (calculatedGivenSlot)
      parts.push(
        `In total, the user will be able to book ${calculatedGivenSlot} hours of lessons.`,
      );
    return parts.join(". ");
  }

  return (
    <form>
      <Col>
        <Row>
          <InputWithLabel label="Start date">
            <Controller
              disabled={disabledMap?.startAtWeek}
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    disabled={disabledMap?.startAtWeek}
                    errorMessage={errors.startAtWeek?.message}
                    onChange={(e) => field.onChange(e)}
                    value={
                      field.value ? dayjs(field.value).format("YYYY-MM-DD") : ""
                    }
                    type={"date"}
                  />
                );
              }}
              name={"startAtWeek"}
            />
          </InputWithLabel>
          <InputWithLabel label="End date">
            <Controller
              disabled={disabledMap?.endAtWeek}
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    disabled={disabledMap?.startAtWeek}
                    errorMessage={errors.startAtWeek?.message}
                    onChange={(e) => field.onChange(e)}
                    value={
                      field.value ? dayjs(field.value).format("YYYY-MM-DD") : ""
                    }
                    type={"date"}
                  />
                );
              }}
              name={"endAtWeek"}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <InputWithLabel label="Weekly lessons count">
            <TextInput
              disabled={disabledMap?.lessonsPerWeek}
              errorMessage={errors.lessonsPerWeek?.message}
              {...register("lessonsPerWeek")}
              type={"number"}
            />
          </InputWithLabel>
        </Row>
        <Row>
          <SpinnerContainer loading={loadingGivenSlotCount}>
            <div className={"bg-blue-200 rounded-md p-3"}>
              <p className={""}>
                {printPlanInfo(startDate, endDate, calculatedGivenSlot)}
              </p>
            </div>
          </SpinnerContainer>
        </Row>
        <Row>
          <div className={"flex w-full justify-end"}>
            <PrimaryButton
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={"Create plan"}
            />
          </div>
        </Row>
      </Col>
    </form>
  );
}
