import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { UserFragmentFragment } from "../gql/graphql.ts";
import { BasicTable } from "../UI/Table/BasicTable.tsx";
import {
  ArrowTopRightOnSquareIcon,
  ClipboardIcon,
  DocumentIcon,
  EllipsisHorizontalIcon,
  ListBulletIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline/index.js";
import { DropDown } from "../UI/DropDown/DropDown.tsx";
import { DropDownItem } from "../UI/DropDown/DropDownItem.tsx";
import copy from "copy-to-clipboard";

type Props = {
  users: UserFragmentFragment[];
  editUser: (user: UserFragmentFragment) => void;
  toFeedbackReport: (user: UserFragmentFragment) => void;
  toUserClassRoomPlan: (user: UserFragmentFragment) => void;
};
export function UserTable(props: Props) {
  const users = props.users;
  const columnHelper = createColumnHelper<UserFragmentFragment>();
  const columns = [
    columnHelper.accessor("email", {
      cell: (info) => (
        <p className={"break-all flex items-center space-x-2 group"}>
          <span>{info.getValue()}</span>
          <span
            onClick={() => {
              copy(info.getValue());
            }}
            className={
              "shadow-md p-1.5 rounded-full invisible group-hover:visible cursor-pointer hover:bg-slate-200"
            }
          >
            <ClipboardIcon className={"w-4 flex-shrink-0"} />
          </span>
        </p>
      ),
      footer: (info) => info.column.id,
      header: "Email",
    }),
    columnHelper.accessor("speakingLevel", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
      header: "Speaking Level",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("lessonFeedbacks", {
      cell: (info) => info.getValue().length,
      footer: (info) => info.column.id,
      header: "Received lesson feedback",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("progressCheckResults", {
      cell: (info) => info.getValue().length,
      footer: (info) => info.column.id,
      header: "Received progress check feedback",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("lessonFeedbacks", {
      cell: (info) => info.getValue().filter((f) => f.noShow).length,
      footer: (info) => info.column.id,
      id: "no-show",
      header: "No show",
      enableColumnFilter: false,
    }),
    columnHelper.display({
      id: "actions",
      cell: (v) => (
        <div className={"flex justify-end"}>
          <div>
            <DropDown
              activator={
                <EllipsisHorizontalIcon
                  className={"text-slate-800 w-5 h-5 group-hover:text-white"}
                />
              }
            >
              <DropDownItem onClick={() => props.editUser(v.row.original)}>
                <PencilSquareIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                Edit
              </DropDownItem>
              <DropDownItem
                onClick={() => props.toFeedbackReport(v.row.original)}
              >
                <DocumentIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                User progress
              </DropDownItem>
              <DropDownItem
                onClick={() => props.toUserClassRoomPlan(v.row.original)}
              >
                <ListBulletIcon className={"w-5 h-5 mr-2 flex-shrink-0"} />
                Class room plans
              </DropDownItem>
              <DropDownItem
                onClick={() =>
                  window.open(
                    `${import.meta.env.VITE_USER_APP_BASE_URL}/impersonate-user?uid=${v.row.original.authId}`,
                    "_blank",
                    "noopener noreferrer",
                  )
                }
              >
                <ArrowTopRightOnSquareIcon
                  className={"w-5 h-5 mr-2 flex-shrink-0"}
                />
                Impersonate
              </DropDownItem>
            </DropDown>
          </div>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    columns,
    data: users as Array<UserFragmentFragment>,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
  });
  return (
    <div>
      <BasicTable table={table} />
    </div>
  );
}
