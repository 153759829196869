import { useUserIdParamsOrThrow } from "../useUserIdParamsOrThrow.ts";
import { useUserClassRoomPlans } from "./useUserClassRoomPlans.ts";
import { FullPageLoading } from "../../UI/Loading/FullPageLoading.tsx";
import { Layout } from "../../UI/Layout/Layout.tsx";
import { ScreenTitleBlock } from "../../UI/Layout/ScreenTitleBlock.tsx";
import { useUserById } from "../useUserById.ts";
import { ClassRoomPlanListItem } from "./ClassRoomPlanListItem.tsx";
import { BreadCrumbsWrapper } from "../../UI/Layout/BreadCrumbsWrapper.tsx";
import { BreadCrumbsItem } from "../../UI/Layout/BreadCrumbsItem.tsx";
import { useToAllUsers } from "../useToAllUsers.ts";
import { InfoFeedback } from "../../UI/Feedback/InfoFeedback.tsx";
import { useToCreateClassRoomPlanScreen } from "./useToCreateClassRoomPlanScreen.ts";
import { useUserRemainingLessons } from "./useUserRemainingLessons.ts";
import _capitalize from "lodash.capitalize";
import { Card } from "../../UI/Card.tsx";
import { useDeleteClassRoomPlan } from "./useDeleteClassRoomPlan.ts";
import { UserFrozenWeekComponent } from "./FreezeWeek/UserFrozenWeekComponent.tsx";

export const AllUserClassRoomPlanScreenPath = "/users/:userId/class-room-plans";
export function AllUserClassRoomPlanScreen() {
  const { userId } = useUserIdParamsOrThrow();
  const {
    classRoomPlans,
    loading: loadingClassRoomPlans,
    refetch,
  } = useUserClassRoomPlans(userId);
  const { user, loading: loadingUser } = useUserById(userId);
  const { remainingLessons, loading: loadingRemainingLessons } =
    useUserRemainingLessons(userId);
  const loading =
    loadingClassRoomPlans || loadingUser || loadingRemainingLessons;

  const { toCreateClassRoomPlan } = useToCreateClassRoomPlanScreen();
  const { toAllUsers } = useToAllUsers();

  const { deleteClassRoomPlan, loading: deletingPlan } =
    useDeleteClassRoomPlan();
  async function onDeletePlan(planId: string) {
    if (window.confirm(`Are you sure you wish to delete this plan?`)) {
      await deleteClassRoomPlan(planId);
      await refetch();
    }
  }

  if (loading) return <FullPageLoading />;
  if (!user) return null; //TODO return not found

  return (
    <Layout>
      <Card>
        <div>
          <ScreenTitleBlock
            ctaOnClick={() => toCreateClassRoomPlan(userId)}
            ctaLabel={"New plan"}
            title={`${user.givenName}'s active plans`}
            subTitle={
              <p>
                {_capitalize(user.givenName)} still has{" "}
                <b>{remainingLessons}</b> hours left to complete
              </p>
            }
            breadCrumbs={
              <BreadCrumbsWrapper>
                <BreadCrumbsItem onClick={toAllUsers} title={"Users"} />
                <BreadCrumbsItem title={"Active plans"} />
              </BreadCrumbsWrapper>
            }
          />
        </div>
      </Card>
      <div className={"mt-4"}>
        {classRoomPlans.length > 0 ? (
          <div className={"space-y-3"}>
            {classRoomPlans.map((p) => {
              return (
                <ClassRoomPlanListItem
                  deleting={deletingPlan}
                  onDelete={onDeletePlan}
                  key={p.id}
                  plan={p}
                />
              );
            })}
          </div>
        ) : (
          <Card>
            <div className={"flex justify-center"}>
              <InfoFeedback
                title={"No active plan"}
                message={`${user.givenName} has no active plan.`}
              />
            </div>
          </Card>
        )}
      </div>
      <div className={"mt-4"}>
        <Card>
          <UserFrozenWeekComponent userId={user.id} />
        </Card>
      </div>
    </Layout>
  );
}
