import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginScreen } from "./Auth/LoginScreen.ts";
import { RequireAuth } from "./Auth/RequireAuth.tsx";
import {
  AllFranchiseeScreenPath,
  FranchiseeScreen,
} from "./Franchisee/FranchiseeScreen.tsx";
import { AuthCtxProvider } from "./Auth/AuthCtxProvider.tsx";
import { AccessTokenScreen } from "./Debug/AccessTokenScreen.tsx";
import { useCreateApolloClient } from "./ApolloClient/useCreateApolloClient.ts";
import { ApolloProvider } from "@apollo/client";
import { useMemo } from "react";
import {
  CreateFranchiseeScreen,
  CreateFranchiseeScreenPath,
} from "./Franchisee/CreateFranchiseeScreen.tsx";
import {
  EditFranchiseeScreen,
  EditFranchiseeScreenPath,
} from "./Franchisee/EditFranchiseeScreen.tsx";
import { AllTopicScreenPath, TopicScreen } from "./Topic/TopicScreen.tsx";
import {
  CreateTopicScreen,
  CreateTopicScreenPath,
} from "./Topic/CreateTopicScreen.tsx";
import { EditTopicPath, EditTopicScreen } from "./Topic/EditTopicScreen.tsx";
import { AllUserScreen, AllUsersScreenPath } from "./User/AllUserScreen.tsx";
import { EditUserScreen, EditUserScreenPath } from "./User/EditUserScreen.tsx";
import {
  CreateUserScreen,
  CreateUserScreenPath,
} from "./User/CreateUserScreen.tsx";
import {
  LessonFeedbackReportScreen,
  LessonFeedbackReportScreenPath,
} from "./User/LessonFeedback/LessonFeedbackReportScreen.tsx";
import {
  AllUserClassRoomPlanScreen,
  AllUserClassRoomPlanScreenPath,
} from "./User/ClassRoomPlan/AllUserClassRoomPlanScreen.tsx";
import {
  CreateClassRoomPlanScreen,
  CreateClassRoomPlanScreenPath,
} from "./User/ClassRoomPlan/CreateClassRoomPlanScreen.tsx";
import { TestScreen, TestScreenPath } from "./TestScreen.tsx";
import {
  ClassRoomDetailScreen,
  ClassRoomDetailScreenPath,
} from "./ClassRoom/ClassRoomDetailScreen.tsx";
import {
  AvailabilityImportScreen,
  AvailabilityImportScreenPath,
} from "./Availability/AvailabilityImportScreen.tsx";
import {
  GenerateAvailabilityCsvScreen,
  GenerateAvailabilityCsvScreenPath,
} from "./Availability/GenerateAvailabilityCsvScreen.tsx";
import {
  AvailabilityReportScreen,
  AvailabilityReportScreenPath,
} from "./Availability/Report/AvailabilityReportScreen.tsx";
import {
  TeacherAvailabilityScreen,
  TeacherAvailabilityScreenPath,
} from "./Franchisee/TeacherAvailability/TeacherAvailabilityScreen.tsx";
import {
  SpeakingLevelChangeSuggestionScreen,
  SpeakingLevelChangeSuggestionScreenPath,
} from "./User/SpeakingLevelSuggestion/SpeakingLevelChangeSuggestionScreen.tsx";
import {
  AgGridClassRoomListPath,
  AgGridClassRoomListScreen,
} from "./ClassRoom/AgGridClassRoomListScreen.tsx";
import {
  CreateClassroomScreen,
  CreateClassroomScreenPath,
} from "./ClassRoom/CreateClassroom/CreateClassroomScreen.tsx";

function App() {
  const { createApolloClient } = useCreateApolloClient();
  const apolloClient = useMemo(
    () => createApolloClient(),
    [createApolloClient],
  );
  return (
    <ApolloProvider client={apolloClient}>
      <AuthCtxProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route
              path={AllFranchiseeScreenPath}
              element={
                <RequireAuth>
                  <FranchiseeScreen />
                </RequireAuth>
              }
            />
            <Route
              path={CreateFranchiseeScreenPath}
              element={
                <RequireAuth>
                  <CreateFranchiseeScreen />
                </RequireAuth>
              }
            />
            <Route
              path={EditFranchiseeScreenPath}
              element={
                <RequireAuth>
                  <EditFranchiseeScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AllTopicScreenPath}
              element={
                <RequireAuth>
                  <TopicScreen />
                </RequireAuth>
              }
            />
            <Route
              path={CreateTopicScreenPath}
              element={
                <RequireAuth>
                  <CreateTopicScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AllUsersScreenPath}
              element={
                <RequireAuth>
                  <AllUserScreen />
                </RequireAuth>
              }
            />
            <Route
              path={EditUserScreenPath}
              element={
                <RequireAuth>
                  <EditUserScreen />
                </RequireAuth>
              }
            />
            <Route
              path={CreateUserScreenPath}
              element={
                <RequireAuth>
                  <CreateUserScreen />
                </RequireAuth>
              }
            />
            <Route
              path={EditTopicPath}
              element={
                <RequireAuth>
                  <EditTopicScreen />
                </RequireAuth>
              }
            />
            <Route
              path={LessonFeedbackReportScreenPath}
              element={
                <RequireAuth>
                  <LessonFeedbackReportScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AllUserClassRoomPlanScreenPath}
              element={
                <RequireAuth>
                  <AllUserClassRoomPlanScreen />
                </RequireAuth>
              }
            />
            <Route
              path={CreateClassRoomPlanScreenPath}
              element={
                <RequireAuth>
                  <CreateClassRoomPlanScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AgGridClassRoomListPath}
              element={
                <RequireAuth>
                  <AgGridClassRoomListScreen />
                </RequireAuth>
              }
            />
            <Route
              path={CreateClassroomScreenPath}
              element={
                <RequireAuth>
                  <CreateClassroomScreen />
                </RequireAuth>
              }
            />
            <Route
              path={ClassRoomDetailScreenPath}
              element={
                <RequireAuth>
                  <ClassRoomDetailScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AvailabilityImportScreenPath}
              element={
                <RequireAuth>
                  <AvailabilityImportScreen />
                </RequireAuth>
              }
            />
            <Route
              path={GenerateAvailabilityCsvScreenPath}
              element={
                <RequireAuth>
                  <GenerateAvailabilityCsvScreen />
                </RequireAuth>
              }
            />
            <Route
              path={AvailabilityReportScreenPath}
              element={
                <RequireAuth>
                  <AvailabilityReportScreen />
                </RequireAuth>
              }
            />
            <Route
              path={TeacherAvailabilityScreenPath}
              element={
                <RequireAuth>
                  <TeacherAvailabilityScreen />
                </RequireAuth>
              }
            />
            <Route
              path={SpeakingLevelChangeSuggestionScreenPath}
              element={
                <RequireAuth>
                  <SpeakingLevelChangeSuggestionScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/token"
              element={
                <RequireAuth>
                  <AccessTokenScreen />
                </RequireAuth>
              }
            />
            <Route path={TestScreenPath} element={<TestScreen />} />
          </Routes>
        </BrowserRouter>
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;
