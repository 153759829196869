import { Logo } from "./Logo.tsx";
import { AllFranchiseeScreenPath } from "../../Franchisee/FranchiseeScreen.tsx";
import {
  AcademicCapIcon,
  CalendarIcon,
  LightBulbIcon,
  ListBulletIcon,
  PlusCircleIcon,
  SquaresPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { AllTopicScreenPath } from "../../Topic/TopicScreen.tsx";
import { AllUsersScreenPath } from "../../User/AllUserScreen.tsx";
import { AvailabilityImportScreenPath } from "../../Availability/AvailabilityImportScreen.tsx";
import { GenerateAvailabilityCsvScreenPath } from "../../Availability/GenerateAvailabilityCsvScreen.tsx";
import { SideMenuButton } from "./SideMenuButton.tsx";
import { useLocation } from "react-router-dom";
import { AvailabilityReportScreenPath } from "../../Availability/Report/AvailabilityReportScreen.tsx";
import { ChartBarSquareIcon } from "@heroicons/react/24/outline/index.js";
import { SpeakingLevelChangeSuggestionScreenPath } from "../../User/SpeakingLevelSuggestion/SpeakingLevelChangeSuggestionScreen.tsx";
import { AgGridClassRoomListPath } from "../../ClassRoom/AgGridClassRoomListScreen.tsx";

export function SideMenu() {
  const location = useLocation();
  const activeRoute = location.pathname;
  return (
    <div
      className={
        "p-4 shadow-lg border-r shadow-slate-200/10 border-slate-200 flex flex-col h-full"
      }
    >
      <Logo />

      <div
        className={"mt-4 w-full flex flex-col space-y-3 overflow-y-auto flex-1"}
      >
        <SideMenuButton
          isActive={activeRoute === AllFranchiseeScreenPath}
          icon={<SquaresPlusIcon className={"h-5 w-5"} />}
          to={AllFranchiseeScreenPath}
          label={"Teachers"}
        />
        <SideMenuButton
          isActive={activeRoute === AllTopicScreenPath}
          icon={<ListBulletIcon className={"h-5 w-5"} />}
          to={AllTopicScreenPath}
          label={"Topics"}
        />
        <SideMenuButton
          isActive={activeRoute === AllUsersScreenPath}
          icon={<UsersIcon className={"h-5 w-5"} />}
          to={AllUsersScreenPath}
          label={"Users"}
        />
        <SideMenuButton
          isActive={activeRoute === AgGridClassRoomListPath}
          icon={<AcademicCapIcon className={"h-5 w-5"} />}
          to={AgGridClassRoomListPath}
          label={"Classrooms"}
        />
        <SideMenuButton
          isActive={activeRoute === AvailabilityImportScreenPath}
          icon={<CalendarIcon className={"h-5 w-5"} />}
          to={AvailabilityImportScreenPath}
          label={"Import availability"}
        />
        <SideMenuButton
          isActive={activeRoute === GenerateAvailabilityCsvScreenPath}
          icon={<PlusCircleIcon className={"h-5 w-5"} />}
          to={GenerateAvailabilityCsvScreenPath}
          label={"Generate Availability CSV"}
        />
        <SideMenuButton
          isActive={activeRoute === AvailabilityReportScreenPath}
          icon={<ChartBarSquareIcon className={"h-5 w-5"} />}
          to={AvailabilityReportScreenPath}
          label={"Availability Report"}
        />
        <SideMenuButton
          isActive={activeRoute === SpeakingLevelChangeSuggestionScreenPath}
          icon={<LightBulbIcon className={"h-5 w-5"} />}
          to={SpeakingLevelChangeSuggestionScreenPath}
          label={"Speaking Level Suggestions"}
        />
      </div>
    </div>
  );
}
