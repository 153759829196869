import { Select } from "@normasteaching/norma-ui";

type Props = {
  value?: boolean;
  onChange: (value: boolean) => void;
};
export function BooleanInput({ value, onChange }: Props) {
  return (
    <Select<"true" | "false">
      value={value ? "true" : "false"}
      options={[
        {
          name: "Sì",
          id: "true",
        },
        {
          name: "No",
          id: "false",
        },
      ]}
      onChange={(v) => {
        onChange(v === "true");
      }}
    />
  );
}
