import { graphql } from "../../gql/index.ts";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

const CreateClassRoomPlanMutation = graphql(`
  mutation createClassRoomPlan(
    $userId: String!
    $startedAtWeekUtc: String!
    $lessonsPerWeek: Int!
    $endedAtWeekUtc: String!
  ) {
    createClassRoomPlan(
      endedAtWeekUtc: $endedAtWeekUtc
      lessonsPerWeek: $lessonsPerWeek
      startedAtWeekUtc: $startedAtWeekUtc
      userId: $userId
    ) {
      ...ClassRoomPlanFragment
    }
  }
`);
export function useCreateClassRoomPlan() {
  const [createClassRoomPlanMutation, { loading }] = useMutation(
    CreateClassRoomPlanMutation,
  );

  const createClassRoomPlan = useCallback(
    async ({
      userId,
      startedAtWeekUtc,
      endedAtWeekUtc,
      lessonsPerWeek,
    }: {
      userId: string;
      startedAtWeekUtc: string;
      lessonsPerWeek: number;
      endedAtWeekUtc: string;
    }) => {
      await createClassRoomPlanMutation({
        variables: {
          startedAtWeekUtc,
          lessonsPerWeek,
          endedAtWeekUtc,
          userId,
        },
      });
    },
    [createClassRoomPlanMutation],
  );

  return {
    createClassRoomPlan,
    loading,
  };
}
