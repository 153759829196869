import { getFragmentData, graphql } from "../../gql/index.ts";
import { useQuery } from "@apollo/client";
import { ClassRoomPlanFragment } from "./ClassRoomPlanFragment.ts";

const GetUserClassRoomPlansQuery = graphql(`
  query getUserClassRoomPlans($userId: String!) {
    getUserClassRoomPlans(userId: $userId) {
      ...ClassRoomPlanFragment
    }
  }
`);
export function useUserClassRoomPlans(userId: string) {
  const { data, loading, refetch } = useQuery(GetUserClassRoomPlansQuery, {
    variables: {
      userId,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    classRoomPlans:
      getFragmentData(ClassRoomPlanFragment, data?.getUserClassRoomPlans) ?? [],
    loading,
    refetch,
  };
}
