import { graphql } from "../../gql/index.ts";

export const ClassRoomPlanFragment = graphql(`
  fragment ClassRoomPlanFragment on UserClassRoomPlan {
    id
    userId
    startedAtWeekUtc
    endedAtWeekUtc
    lessonsPerWeek
    numberOfSlotIncluded
  }
`);
